import React from "react";
import Modal from "../../../components/Modals";
import moment from "moment";

const ModalDriverFreightEvents = ({ actionModal, openModal, selectedEntity }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-12/12 lg:w-6/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Eventos</h5>
                </div>
            }
        >
            <>
                {selectedEntity?.events?.length > 0 ? (
                    <table className="table-auto mt-10 w-full text-left">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Velocidade</th>
                                <th>Veículo</th>
                                <th>Motorista</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedEntity?.events?.map(event => (
                                <tr>
                                    <td>{event.latitude}</td>
                                    <td>{event.longitude}</td>
                                    <td>{event.speed}</td>
                                    <td>{event.vehicle.licensePlate}</td>
                                    <td>{event.userDriverName}</td>
                                    <td>{moment(event.receivedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Viagem sem eventos</p>
                )}
            </>
        </Modal>
    );
};

export default ModalDriverFreightEvents;
